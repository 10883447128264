
'use client'

import { Fragment, useState } from 'react';
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import Logo from './Logo';
import SlideOverSupport from './SlideOverSupport';
import { 
     SquaresPlusIcon, 
     Square3Stack3DIcon,
     FingerPrintIcon, 
     LockClosedIcon, 
     ShoppingBagIcon, 
     CodeBracketIcon, 
     PlayCircleIcon,
     CursorArrowRippleIcon, 
     ServerStackIcon,
     Bars3Icon, 
     XMarkIcon, 
     PhoneIcon
} from '@heroicons/react/24/outline';


export default function NavigationMenu() {

     const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

     return(
          <Fragment>
               <nav className="mx-auto flex items-start justify-between p-6 lg:px-8" aria-label="Global">
                    <div className="flex-none lg:flex-1">
                         <Logo 
                              logoColor="stroke-white" 
                              logoPosition="fixed"
                         />
                    </div>
                    <div className="flex lg:hidden">
                         <button
                              type="button"
                              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                              onClick={() => setMobileMenuOpen(true)}
                         >
                              <span className="sr-only">Open main menu</span>
                              <Bars3Icon className="h-8 w-8 text-brand" aria-hidden="true" />
                         </button>
                    </div>
                    <Popover.Group className="hidden lg:flex lg:gap-x-8 xl:pt-4 lg:justify-end">
                         {/* project menu */}
                         <Popover className="relative">
                              <Popover.Button className="flex items-center gap-x-1 leading-6 text-gray-900">
                                   Projects
                                   <ChevronDownIcon 
                                        className="h-5 w-5 flex-none text-brand" 
                                        aria-hidden="true" 
                                   />
                              </Popover.Button>
                              <Transition
                                   as={Fragment}
                                   enter="transition ease-out duration-200"
                                   enterFrom="opacity-0 translate-y-1"
                                   enterTo="opacity-100 translate-y-0"
                                   leave="transition ease-in duration-150"
                                   leaveFrom="opacity-100 translate-y-0"
                                   leaveTo="opacity-0 translate-y-1"
                                   >
                                   <Popover.Panel className="absolute -right-60 top-full z-10 mt-3 w-screen max-w-md lg:max-w-4xl overflow-hidden rounded-2xl bg-white shadow-lg ring-1 ring-gray-900/5">
                                        <div className="flex flex-row">
                                             <div className="basis-1/2 p-6 bg-white">
                                                  {/* news spotlight */}
                                                  <a href="https://www.hostbot.ca" target="_blank" rel="noreferrer">
                                                       <img 
                                                            src="/images/SketchedLaptopWork.png" 
                                                            width="500"
                                                            height="300" 
                                                            alt="Making Websites Fun Again"
                                                            className="rounded mb-5"
                                                       /> 
                                                  </a>
                                                  <h3 className="semi-bold font-bold">
                                                       <a href="https://www.hostbot.ca" target="_blank" rel="noreferrer">
                                                            HostBot.ca Gets REACT-ive with New Brand
                                                       </a>
                                                  </h3>
                                                  <p className="text-xs text-brand mb-2">
                                                       June 23, 2024 at 10:32 AM
                                                  </p>
                                                  <p className="text-xs">
                                                       Host Bot&apos;s corporate website combines a fresh brand with the 
                                                       emerging REACT framework. This versitility allows customers to 
                                                       interactively access support tools quickly.
                                                  </p>
                                                  
                                                  <div className="btn-group btn-group-vertical lg:btn-group-horizontal text-sm mt-4">
                                                       <a href="https://www.hostbot.ca" 
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            className="btn font-bold underline underline-offset-4 mr-5 hover:text-brand">
                                                            View HostBot&apos;s website
                                                       </a>
                                                  </div>
                                                  {/* news spotlight */}
                                             </div>
                                             <div className="basis-1/2 p-6 pt-1 bg-gray-50">
                                                  {/* project listings */}
                                                  <ul className="mt-4 space-y-6">
                                                       {projectListings.map((project) => (
                                                            <li key={ project.id }>
                                                                 <span className="text-gray-700 hover:text-gray-700 font-bold">
                                                                      { project.name }
                                                                 </span>
                                                                 <p className="text-xs text-brand mb-2">{ project.date }</p>
                                                                 <p className="text-xs">{ project.description }</p>
                                                            </li>
                                                       ))}
                                                  </ul>
                                                  {/* project listings */}
                                             </div>
                                        </div>
                                   </Popover.Panel>
                              </Transition>
                         </Popover>
                         {/* project menu */}
                         
                         {/* services menu */}
                         <Popover className="relative">
                              <Popover.Button className="flex items-center gap-x-1 leading-6 text-gray-900">
                                   Services
                                   <ChevronDownIcon 
                                        className="h-5 w-5 flex-none text-brand" 
                                        aria-hidden="true" 
                                   />
                              </Popover.Button>
                              <Transition
                                   as={Fragment}
                                   enter="transition ease-out duration-200"
                                   enterFrom="opacity-0 translate-y-1"
                                   enterTo="opacity-100 translate-y-0"
                                   leave="transition ease-in duration-150"
                                   leaveFrom="opacity-100 translate-y-0"
                                   leaveTo="opacity-0 translate-y-1"
                                   >
                                   <Popover.Panel className="absolute -right-40 top-full z-10 mt-3 w-screen max-w-md lg:max-w-4xl overflow-hidden rounded-2xl bg-white shadow-lg ring-1 ring-gray-900/5">
                                        
                                        <div className="gap-4 p-6">
                                             <h2 className="mb-1 text-3xl font-semibold">
                                                  Services
                                                  <span className="text-brand">.</span>
                                             </h2>
                                             <p className="text-sm">Our services deliver support for content management systems such as Magento, 
                                                  Shopify, Wordpress and Drupal. Weather you host a complex website or a
                                                  private data network, customized software can be programmed to rapidly organize 
                                                  your business.</p>
                                        </div>

                                        <div className="grid grid-cols-3 gap-4 px-6">
                                             <h3 className="font-bold">Development</h3>
                                             <h3 className="font-bold">Hosting</h3>
                                             <h3 className="font-bold">Ecommerce</h3>
                                        </div>
                                        
                                        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4 p-6 pt-3">
                                             {serviceList.map((item) => (
                                                  <div key={item.name}
                                                       className="group relative flex items-start gap-x-2 p-4 rounded-lg text-sm bg-slate-50 hover:bg-slate-100"
                                                       >
                                                       <div className="flex flex-none h-11 w-13 px-1 items-start justify-center rounded-lg">
                                                            <item.icon 
                                                                 className="h-8 w-8 text-brand" 
                                                                 aria-hidden="true" 
                                                            />
                                                       </div>
                                                       <div className="flex-auto">
                                                            <span className="block font-semibold text-gray-900">
                                                                 {item.name}
                                                                 <span className="absolute inset-0" />
                                                            </span>
                                                            <p className="mt-1 text-sm text-gray-600">
                                                                 {item.description}
                                                            </p>
                                                       </div>
                                                  </div>
                                             ))}
                                        </div>
                                        <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-slate-100 hover:bg-slate-150">
                                             {callsToAction.map((item) => (
                                                  <a href={item.href} key={item.name}
                                                       className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100"
                                                       >
                                                       <item.icon 
                                                            className="h-5 w-5 flex-none text-gray-400" 
                                                            aria-hidden="true" 
                                                       />
                                                       {item.name}
                                                  </a>
                                             ))}
                                        </div>
                                   </Popover.Panel>
                              </Transition>
                         </Popover>
                         {/* services menu */}

                         {/* news menu */}
                         <Popover className="relative">
                              <Popover.Button className="flex items-center gap-x-1 leading-6 text-gray-900">
                                   News
                                   <ChevronDownIcon 
                                        className="h-5 w-5 flex-none text-brand" 
                                        aria-hidden="true" 
                                   />
                              </Popover.Button>
                              <Transition
                                   as={Fragment}
                                   enter="transition ease-out duration-200"
                                   enterFrom="opacity-0 translate-y-1"
                                   enterTo="opacity-100 translate-y-0"
                                   leave="transition ease-in duration-150"
                                   leaveFrom="opacity-100 translate-y-0"
                                   leaveTo="opacity-0 translate-y-1"
                                   >
                                   <Popover.Panel className="absolute -right-24 top-full z-10 mt-3 w-screen max-w-md lg:max-w-3xl overflow-hidden rounded-2xl bg-white shadow-lg ring-1 ring-gray-900/5">
                                        <div className="flex flex-row">
                                             <div className="basis-1/2 p-6 pt-2 bg-gray-50">

                                                  {/* news postings */}
                                                  <ul className="mt-4 space-y-6">
                                                       {newsListings.map((post) => (
                                                            <li key={ post.id }>
                                                                 <a href={ post.href } className="text-gray-700 hover:text-gray-700 font-bold">
                                                                      { post.name }
                                                                 </a>
                                                                 <p className="text-xs text-brand mb-2">{ post.date }</p>
                                                                 <p className="text-xs">{ post.description }</p>
                                                            </li>
                                                       ))}
                                                  </ul>
                                                  {/* news postings */}

                                             </div>
                                             <div className="basis-1/2 p-6 bg-white">
                                                  {/* news spotlight */}
                                                  <a href="https://helpx.adobe.com/ca/security/products/magento/apsb22-48.html" target="_blank" rel="noreferrer">
                                                       <img 
                                                            src="/images/Magento2Security.png" 
                                                            width="500"
                                                            height="300" 
                                                            alt="Magento Security Update (APSB22-48)"
                                                            className="rounded mb-5"
                                                       />
                                                  </a>
                                                  <h3 className="semi-bold font-bold">
                                                       <a href="https://helpx.adobe.com/ca/security/products/magento/apsb22-48.html" target="_blank" rel="noreferrer">
                                                            Adobe Releases APSB22-48 Update!
                                                       </a>
                                                  </h3>
                                                  <p className="text-xs text-brand mb-2">
                                                       October 27 2022 at 10:32 AM
                                                  </p>
                                                  <p className="text-xs">
                                                       Adobe releases a security update (APSB22-48) for Adobe Commerce and Magento Open Source. 
                                                       This update resolves a critical and medium vulnerability. 
                                                  </p>
                                                  
                                                  <div className="btn-group btn-group-vertical lg:btn-group-horizontal text-sm mt-4">
                                                       <a href="https://helpx.adobe.com/ca/security/products/magento/apsb22-48.html" 
                                                          className="btn font-bold underline underline-offset-4 mr-5 hover:text-brand"
                                                          target="_blank"
                                                          rel="noreferrer">
                                                            Release Details
                                                       </a>
                                                       <a href="https://support.magento.com/hc/en-us/articles/4426353041293-Security-updates-available-for-Adobe-Commerce-APSB22-12" 
                                                          className="btn underline underline-offset-4 hover:text-brand"
                                                          target="_blank"
                                                          rel="noreferrer">
                                                            Security Releases
                                                       </a>
                                                  </div>
                                                  {/* news spotlight */}
                                             </div>
                                        </div>
                                   </Popover.Panel>
                              </Transition>
                         </Popover>
                         {/* news menu */}

                         {/* support trigger */}
                         <SlideOverSupport />
                         {/* support trigger */}
                    </Popover.Group>
               </nav>
               <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                    <div className="fixed inset-0 z-10" />
                    <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-xl sm:ring-1 sm:ring-gray-900/10">
                         <div className="flex items-center justify-between">
                              <a href="#/" className="-m-1.5 p-1.5 text-brand">
                                   inVision.Web
                              </a>
                              <button
                                   type="button"
                                   className="-m-2.5 rounded-md p-2.5 text-gray-700"
                                   onClick={() => setMobileMenuOpen(false)}
                                   >
                                   <span className="sr-only">Close menu</span>
                                   <XMarkIcon className="h-6 w-6 text-brand" aria-hidden="true" />
                              </button>
                         </div>
                         <div className="mt-6 flow-root">
                              <div className="-my-2 divide-y divide-gray-500/10">
                              <div className="space-y-2 py-2">
                                   <Disclosure as="div" className="-mx-3">
                                   {({ open }) => (
                                   <>
                                        <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 hover:bg-gray-50">
                                        Product
                                        <ChevronDownIcon
                                             className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                                             aria-hidden="true"
                                        />
                                        </Disclosure.Button>
                                        <Disclosure.Panel className="grid grid-cols-2 gap-6 text-sm p-4">
                                             {[...projectListings].map((item) => (
                                                  <div key={item.name}>
                                                       <p className="font-semibold text-black">{item.name}</p>
                                                       <p>{item.description}</p>
                                                  </div>
                                             ))}
                                        </Disclosure.Panel>
                                   </>
                                   )}
                                   </Disclosure>
                              </div>
                              <div className="space-y-2 py-2">
                                   <Disclosure as="div" className="-mx-3">
                                   {({ open }) => (
                                   <>
                                        <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 hover:bg-gray-50">
                                        Services
                                        <ChevronDownIcon
                                             className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                                             aria-hidden="true"
                                        />
                                        </Disclosure.Button>
                                        <Disclosure.Panel className="grid grid-cols-2 gap-6 text-sm p-4">
                                             {[...serviceList].map((item) => (
                                                  <div key={item.name}>
                                                       <p className="font-semibold text-black">{item.name}</p>
                                                       <p>{item.description}</p>
                                                  </div>
                                             ))}
                                        </Disclosure.Panel>
                                   </>
                                   )}
                                   </Disclosure>
                              </div>
                              <div className="space-y-2 py-2">
                                   <Disclosure as="div" className="-mx-3">
                                   {({ open }) => (
                                   <>
                                        <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 hover:bg-gray-50">
                                        News
                                        <ChevronDownIcon
                                             className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                                             aria-hidden="true"
                                        />
                                        </Disclosure.Button>
                                        <Disclosure.Panel className="grid grid-cols-2 gap-6 text-sm p-4">
                                             {[...newsListings].map((item) => (
                                                  <div key={item.name}>
                                                       <p className="font-semibold text-black">{item.name}</p>
                                                       <p className="text-brand">{item.date}</p>
                                                       <p>{item.description}</p>
                                                  </div>
                                             ))}
                                        </Disclosure.Panel>
                                   </>
                                   )}
                                   </Disclosure>
                              </div>
                              <div className="py-6">
                                   {/* support trigger */}
                                        <SlideOverSupport />
                                   {/* support trigger */}
                              </div>
                              </div>
                         </div>
                    </Dialog.Panel>
               </Dialog>
          </Fragment>
     )
}


const serviceList = [
     {
          name: 'User Interface Design',
          description: 'Speak to customers in a meaningful way through intuitive user experiences.',
          icon: CursorArrowRippleIcon,
     },
     { 
          name: 'Server Management', 
          description: 'Maintain software and the data it hosts through regular security updates.', 
          icon: ServerStackIcon
     },
     {
          name: 'POS Integration',
          description: 'Integrate cutting-edge POS hardware into your stores locations at cost value.',
          icon: ShoppingBagIcon
        },
     {
          name: 'Web Programming',
          description: 'We program in any software that clients find familiar and easy to use.',
          icon: CodeBracketIcon
     },
     {
          name: 'Intranet Systems',
          description: 'Create a secure team environment for staff to share key information.',
          icon: SquaresPlusIcon
        },
        { 
          name: 'Purchase Integration', 
          description: 'Extend the purchase experience  with popular payment methods.', 
          icon: FingerPrintIcon
     },
        {
          name: 'Information Architecture',
          description: 'Improve UX through targeted messaging and responsible content building.',
          icon: Square3Stack3DIcon
          },
          {
          name: 'Security Consulting',
          description: 'Maintain customer safety and platform data through responsible security.',
          icon: LockClosedIcon
          },

     { 
          name: 'Business Automation', 
          description: 'Strategic funnels that drive leads, and target conversion through user interaction.', 
          icon: PlayCircleIcon
     },
];


const newsListings = [
     { 
          id: 1, 
          name: 'New ShellBot DDoS Malware Variants Targeting Poorly Managed Linux Servers', 
          description: 'ASEC said it identified three different ShellBot versions – LiGhT\'s Modded perlbot v2, DDoS PBot v2.0, and PowerBots (C) GohacK – the first two of which offer a variety of DDoS attack commands using HTTP, TCP, and UDP protocols.',
          date: 'November 7 2023 at 3:20 PM',
          href: 'https://thehackernews.com/2023/03/new-shellbot-ddos-malware-targeting.html' 
     },
     { 
          id: 2, 
          name: 'Preventing Insider Threats in Your AD', 
          description: 'Active Directory (AD) is a powerful authentication and directory service used by organizations worldwide. With this ubiquity and power comes the potential for abuse. Insider threats offer some of the most potentials for destruction.',
          date: 'September 30, 2023 at 11:43 AM',
          href: 'https://thehackernews.com/2023/03/preventing-insider-threats-in-your.html' 
     }
];


const projectListings = [
     { 
          id: 1, 
          name: 'The Cowichan Valley School District (SD79) Launches New Look for 2024-25 year.', 
          description: 'With REACT fully integrated into Wordpress core, the user experience for management content has never been easier for school staff. Networked content tools allow the school staff and parents to be synconinzed with the latest disitrict updates.',
          date: 'August 2 2024, 11:43 AM',
          href: 'https://sd79.bc.ca/board-of-education/strategic-plan/' 
     },
     { 
          id: 2, 
          name: 'International Field Trips Feature for 2024-25 District-Wide Rollout', 
          description: 'This anticipated application release (v3.1.19) sees a fresh interface addition that comprehensively guides district staff through the required complexities of registering field trips internationally.',
          date: 'July 17 2024, 3:43 PM',
          href: 'https://sd68.bc.ca/' 
     },
     { 
          id: 3, 
          name: 'REV Engineering Launches New Platform', 
          description: 'The migration from Drupal to WordPress marked a transformative shift in how REV Engineering creates and manages content. Page building tools continue to provide clients with creative avenues to grow their brands online.',
          date: 'June 3 2024, 3:43 PM',
          href: 'https://reveng.ca/' 
     }
];


const callsToAction = [
     { 
          name: 'Technical Support', 
          href: 'mailto:sos@invisionweb.ca', 
          icon: PhoneIcon
     },
     { 
          name: 'Contact Sales Department', 
          href: 'mailto:client@invisionweb.ca', 
          icon: PhoneIcon
     }
]
   

function classNames(...classes) {
     return classes.filter(Boolean).join(' ')
}