
import React, { Fragment, useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { QuestionMarkCircleIcon } from '@heroicons/react/20/solid';
import "react-toastify/dist/ReactToastify.css";



/**
 * Support and General contact form 
 * 
 * @param { data } object 
 * 
*/
export default function SlideOverSupport() {

     /** 
      * Define SlideOver State
      *
     */
     const [open, setOpen] = useState(false);
     const requestForm     = useRef();


     /** 
      * Contact Form handler
      * 
      * @var  { username }
      * @var  { template }
      * 
      * @array form data 
      * 
     */
     const sendEmail = (submissionEvent) => {

          submissionEvent.preventDefault();

          /**
           * Mailer Function with notification (toast) message 
           * 
          */
          emailjs.sendForm(
               'service_requests_doa74a5', 
               'template_upz38bg', 
               requestForm.current, 
               '-N2oCE-_d5Ayarplr'
          ).then((result) => {
               setOpen(false);
               toast.success("Success Notification !", {
                    position: toast.POSITION.TOP_CENTER
               });
               
            }, (error) => {
               setOpen(false);
               toast.error("Error Notification !", {
                    position: toast.POSITION.TOP_CENTER
               }); 
          }); 
          
     };

     return(
          <Fragment>

               {/* Support Link Trigger */}
               <button 
                    onClick={() => setOpen(true)} 
                    className="leading-6 text-gray-900">
                    Support
               </button>


               {/* Slideover View */}
               <Transition.Root show={open} as={Fragment}>
                    <Dialog as="div" className="relative z-50" onClose={setOpen}>
                         <Transition.Child
                              as={Fragment}
                              enter="ease-in-out duration-500"
                              enterFrom="opacity-0"
                              enterTo="opacity-100"
                              leave="ease-in-out duration-500"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                              >
                              <div className="fixed inset-0 bg-slate-500 bg-opacity-75 transition-opacity" />
                         </Transition.Child>
                         
                         <div className="fixed inset-0" />
                         
                         <div className="fixed inset-0 overflow-hidden  z-50">
                              <div className="absolute inset-0 overflow-hidden">
                                   <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                                        <Transition.Child
                                             as={Fragment}
                                             enter="transform transition ease-in-out duration-500 sm:duration-700"
                                             enterFrom="translate-x-full"
                                             enterTo="translate-x-0"
                                             leave="transform transition ease-in-out duration-500 sm:duration-700"
                                             leaveFrom="translate-x-0"
                                             leaveTo="translate-x-full"
                                             >
                                             <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                                                  <form 
                                                       className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
                                                       ref={ requestForm } 
                                                       onSubmit={ sendEmail } 
                                                       noValidate
                                                       >
                                                       <div className="flex-1">

                                                            {/* Dialog Header */}
                                                            <div className="bg-gray-100 px-4 py-6 sm:px-6">
                                                                 <div className="flex items-start justify-between space-x-3">
                                                                      <div className="space-y-1">
                                                                           <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                                                                                Require Support? Have General Questions?
                                                                           </Dialog.Title>
                                                                           <p className="text-sm text-gray-500">
                                                                                Reach out to us for free consultations or direct assistance on specific support matters. 
                                                                                Fill in your information below as best you can, and we will reach out as soon as possible.
                                                                           </p>
                                                                      </div>
                                                                      <div className="flex h-7 items-center">
                                                                           <button
                                                                                type="button"
                                                                                className="text-brand hover:text-gray-500"
                                                                                onClick={() => setOpen(false)}
                                                                           >
                                                                                <span className="sr-only">Close panel</span>
                                                                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                                                           </button>
                                                                      </div>
                                                                 </div>
                                                            </div>

                                                            {/* Divider container */}
                                                            <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">

                                                            {/* Company name */}
                                                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                                                 <div>
                                                                 <label
                                                                      htmlFor="company-name"
                                                                      className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                                                 >
                                                                      Company Name
                                                                 </label>
                                                                 </div>
                                                                 <div className="sm:col-span-2">
                                                                 <input
                                                                      type="text"
                                                                      name="company-name"
                                                                      id="company-name"
                                                                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                 />
                                                                 </div>
                                                            </div>

                                                            {/* Email Address */}
                                                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                                                 <div>
                                                                 <label
                                                                      htmlFor="email-address"
                                                                      className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                                                 >
                                                                      Email Address
                                                                 </label>
                                                                 </div>
                                                                 <div className="sm:col-span-2">
                                                                 <input
                                                                      type="text"
                                                                      name="email-address"
                                                                      id="cemail-address"
                                                                      placeholder="email@website.com"
                                                                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                 />
                                                                 </div>
                                                            </div>

                                                            {/* Request description */}
                                                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                                                 <div>
                                                                 <label
                                                                      htmlFor="project-description"
                                                                      className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                                                 >
                                                                      Request Description
                                                                 </label>
                                                                 </div>
                                                                 <div className="sm:col-span-2">
                                                                 <textarea
                                                                      id="request-description"
                                                                      name="request-description"
                                                                      rows={6}
                                                                      className="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:py-1.5 sm:text-sm sm:leading-6"
                                                                 />
                                                                 </div>
                                                            </div>

                                                            {/* Project Type */}
                                                            <fieldset className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                                                 <legend className="sr-only">Privacy</legend>
                                                                 <div className="text-sm font-medium leading-6 text-gray-900" aria-hidden="true">
                                                                      Request Type
                                                                 </div>
                                                                 <div className="space-y-5 sm:col-span-2">
                                                                      <div className="space-y-5 sm:mt-0">
                                                                           <div className="relative flex items-start">
                                                                                <div className="absolute flex h-6 items-center">
                                                                                     <input
                                                                                          id="sales-request"
                                                                                          name="request-type"
                                                                                          aria-describedby="sales-request-description"
                                                                                          type="radio"
                                                                                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                                                          value="Sales Support"
                                                                                          defaultChecked
                                                                                     />
                                                                                </div>
                                                                                <div className="pl-7 text-sm leading-6">
                                                                                     <label htmlFor="sales-request" className="font-medium text-gray-900">
                                                                                          Sales Department
                                                                                     </label>
                                                                                     <p id="sales-request-description" className="text-gray-500">
                                                                                          I&apos;m requesting a consultation or change of service
                                                                                     </p>
                                                                                </div>
                                                                           </div>
                                                                           <div className="relative flex items-start">
                                                                                <div className="absolute flex h-6 items-center">
                                                                                     <input
                                                                                          id="technical-support"
                                                                                          name="request-type"
                                                                                          aria-describedby="technical-support-description"
                                                                                          type="radio"
                                                                                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                                                          value="Technical Support"
                                                                                     />
                                                                                </div>
                                                                                <div className="pl-7 text-sm leading-6">
                                                                                     <label htmlFor="technical-support" className="font-medium text-gray-900">
                                                                                          Technical Support
                                                                                     </label>
                                                                                     <p id="technical-support-description" className="text-gray-500">
                                                                                          I&apos;m encountering unexpected issues with my website
                                                                                     </p>
                                                                                </div>
                                                                           </div>
                                                                      </div>
                                                                      <hr className="border-gray-200" />
                                                                      <div className="space-between sm:space-between flex flex-col space-y-4 sm:flex-row sm:items-center sm:space-y-0">
                         
                                                                           <div>
                                                                           <a
                                                                           href="#/"
                                                                           rel="noreferrer"
                                                                           className="group flex items-center space-x-2.5 text-sm text-gray-500 hover:text-gray-900"
                                                                           >
                                                                           <QuestionMarkCircleIcon
                                                                                className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                                                aria-hidden="true"
                                                                           />
                                                                           <span>Support should expect 20 minutes response times</span>
                                                                           </a>
                                                                           </div>
                                                                      </div>
                                                                 </div>
                                                            </fieldset>
                                                            </div>
                                                            </div>

                                                            {/* Action buttons */}
                                                            <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                                                                 <div className="flex justify-end space-x-3">
                                                                 <button
                                                                      type="button"
                                                                      className="rounded-md bg-white py-2 px-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-slate-100"
                                                                      onClick={() => setOpen(false)}>
                                                                      Cancel
                                                                 </button>
                                                                 <button
                                                                      type="submit"
                                                                      className="inline-flex justify-center rounded-md bg-brand py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-slate-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                                                      Create Request
                                                                 </button>
                                                            </div>

                                                       </div>
                                                  </form>
                                             </Dialog.Panel>
                                        </Transition.Child>
                                   </div>
                              </div>
                         </div>
                    </Dialog>
               </Transition.Root>


               {/* Notification Message */}
               <ToastContainer autoClose={5000} />

          </Fragment>
     )
}