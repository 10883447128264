'use client'

import React from 'react';
import { motion } from "framer-motion";



/**
 * 
 * @param { * } props 
 * @returns 
 * 
 */
export default function Logo(props) {

     const logoColor = props.logoColor;
     const logoPosition = props.logoPosition;

     return(
          <React.Fragment>
               <motion.div 
                    className={ "z-[1] w-8 mt-2 " + logoPosition } 
                    whileHover={{ rotate: -90, transition: { type: "spring", duration: 2.95, bounce: 0.5 } }} 
                    whileTap={{ scale: 0.8 }}
                    >
                    <a href="#top">
                         <svg id="logo" 
                              viewBox="20 20 46 46" 
                              className={ "w-10 w-10 top-4 hover:cursor-pointer " }
                              >
                              <rect 
                                   width="50" 
                                   height="50"
                                   className={logoColor  + " stroke-15p fill-none"}
                              />
                              <motion.rect
                                   width="50" 
                                   height="50"
                                   className={logoColor  + " stroke-15p fill-none"}
                              />
                         </svg>  
                    </a> 
               </motion.div> 
          </React.Fragment>
     )
}