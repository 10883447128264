import React from "react";
import { useEffect, Fragment } from 'react';
import { motion } from 'framer-motion';
import { MessengerChat } from "react-messenger-chat-plugin";
import NavigationMenu from './components/NavigationMenu';
import { ChevronRightIcon } from '@heroicons/react/20/solid';




/**
 * Render Home Page View
 * 
 * @param { data } object 
 * 
*/
export default function App() {

     const loggedInGreeting = "Hello there! Support is presently online. Feel welcome to reach out anytime.";
     const loggedOutGreeting = "We're presently offline. Please leave a message as the support department is notified.";

     
     useEffect(() => {
          document.body.classList.add('home-test');
          document.title = 'INVISION.WEB ~ Content First, Scalable Web Services ++:..:.::-----....';
     }, []);

     return(
          <Fragment>
               <header>
                    <NavigationMenu />
               </header>
               <div>
                    <div className="flex flex-col lg:flex-row h-screen p-6 lg:pr-0">
                         {/* Introduction Messaging */}
                         <motion.div 
                              className="basis-full lg:basis-3/5 xl:basis-1/2 lg:pl-32 lg:pr-4 lg:py-10 xl:py-24 xl:pl-40"
                              variants={ intoductionContainer } 
                              initial="hidden"
                              animate="show"
                              >
                              <motion.p className="text-brand text-2xl" variants={ animateInitialLoad }>
                                   inVision Web
                              </motion.p>
                              <motion.h1 
                                   className="text-4xl md:text-5xl lg:text-5xl xl:text-7xl py-4"
                                   variants={ animateInitialLoad }
                                   >
                                   Rethinking your user experience
                                   <span className="text-brand">.</span>
                              </motion.h1>
                              <motion.p className="leading-relaxed">
                                   inVision provides web services and trusted tech support to education, private sector and 
                                   enterprise level business. We pride ourselves on delivering responsible content building, 
                                   synchronized marketing and user-focused web experiences.
                              </motion.p> 

                              <motion.button 
                                   type="button" 
                                   className="inline-flex pr-4 py-4 mt-3 mt-2rounded-md hover:text-black"
                                   >
                                   <ChevronRightIcon 
                                        className="mr-2 h-6 w-6 text-brand" 
                                        aria-hidden="true"
                                   />
                                   <a href="/#feature">Responsible, thoughtful content building</a>
                              </motion.button>

                         </motion.div>
                         {/* Introduction Messaging */}

                         {/* Feature Graphic */}
                         <div className="hidden lg:inline-block lg:inline-flex lg:basis-2/5 xl:basis-1/2">
                              <motion.div variants={ heroFeatureImage }
                                   initial="hidden"
                                   animate="show"
                                   className="xl:absolute xl:right-0"
                                   >
                                   <img 
                                        src="/images/WebDevelopmentFunBanner.jpg" 
                                        width="690"
                                        height="540"
                                        alt="Making Websites Fun Again"
                                   /> 
                              </motion.div>
                         </div>
                         {/* Feature Graphic */}
                    </div>
               </div>
               <div>
                    <div id="feature" className="h-screen">
                         <div className="flex flex-col md:flex-row min-h-1/4 gap-8 xl:gap-12 p-6">
                              <div className="md:basis-1/2 xl:basis-1/3 h-full pt-16 md:pt-0 md:pl-20 lg:pl-40">
                                   <h3 className="text-2xl">
                                        An intuitive user experience relies  
                                        on thoughtful content building
                                        <span className="text-brand">.</span>
                                   </h3>
                              </div>
                              <div className="hidden xl:inline-block lg:basis-1/3 h-full">
                                   <p>Our wireframes provide detailed, HTML rich 
                                   storyboards for clients to continually interact and grow 
                                   their website application. Infact, it&apos;s ever been easier
                                   or more affordable to fully customize a system specifically for your 
                                   business.</p>
                                   <p className="mt-4">For more than twenty years, Invision Web 
                                   has ensured that their clients inherit a web site that is future 
                                   proofed and scalable for every device in the world.</p>
                              </div>
                              <div className="md:basis-1/2 xl:basis-1/3 h-full pb-4 md:px-0">
                                   {portfolioFeature.map((post) => (
                                        <article key={post.id} className="flex max-w-xl flex-col items-start justify-between">
                                             <div className="group relative">
                                                  <h3 className="text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                                                       <a href={post.href} target="_blank" rel="noreferrer">
                                                            <span className="absolute inset-0" />
                                                            {post.title}
                                                       </a>
                                                  </h3>
                                                  <p className="mt-2 text-sm italic leading-6 text-gray-600">
                                                       &quot; {post.description} &quot;
                                                  </p>
                                             </div>
                                             <div className="relative mt-4 flex items-center gap-x-4">
                                                  <img
                                                       className="h-10 w-10 flex-none rounded-full bg-gray-50 border-brand border-2"
                                                       src={post.author.imageUrl}
                                                       alt=""
                                                  />
                                                  <div className="text-sm">
                                                       <p className="font-semibold text-gray-900">
                                                            <a href={post.href}>
                                                                 <span className="absolute inset-0" />
                                                                 {post.author.name}
                                                            </a>
                                                       </p>
                                                       <p className="text-gray-600">{post.author.role}</p>
                                                  </div>
                                             </div>
                                        </article>
                                   ))}
                              </div>
                         </div>
                         <div className="flex flex-row h-3/4">
                              <div className="basis-full bg-[url('../../public/images/FeatureClientSplash-SD68Docs.jpg')] bg-cover bg-fixed">
                                   &nbsp;
                              </div>
                         </div>
                    </div>
               </div>
               <div className="relative z-0">
                    <MessengerChat
                         pageId="367151930058006"
                         themeColor={"#fb263b"}
                         bottomSpacing={30}
                         loggedInGreeting={ loggedInGreeting }
                         loggedOutGreeting={ loggedOutGreeting }
                         greetingDialogDisplay={"show"}
                    />
               </div>

          </Fragment>
     )
}



/**
 * Framer motion style variants
 * 
*/
const animateInitialLoad = {
     hidden: { 
          opacity: 0 
     },
     show: {
          opacity: 1,
          transition: {
               duration: 1.20,
               stiffness: 500,
               damping: 100
          }
     }
}


const intoductionContainer = {
     hidden: { 
          opacity: 0,
          y: 100 
     },
     show: {
          opacity: 1,
          y: 0,
          transition: {
               stiffness: 400, 
               damping: 100,
               duration: 1.5
          }
     }
}


const heroFeatureImage = {
     hidden: { 
          opacity: 0,
          y: 100
     },
     show: {
          opacity: 1,
          y: 0,
          transition: { 
               stiffness: 200,
               duration: 1.5
          }
     }
}

const portfolioFeature = [
     {
       id: 1,
       title: 'Nanaimo Ladysmith Public Schools (SD68)',
       href: 'https://www.sd68.bc.ca',
       description:
         'Since 2014 inVision Web has continuously redesigned our online presence to ensure we remain relevant and at the forefront of design and user experience. Their design experience and knowledge of backend infrastructure takes a tremendous load of me and my team. ',
       author: {
         name: 'Dale Burgos',
         role: 'Senior Communications Director',
         href: '#',
         imageUrl:
           '/images/DaleBurgos.jpg',
       }
     }
   ]
