import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import ReactGA from 'react-ga';
import './styles/production.css'



/**
 * Initialize Analytic Tracking
 * 
 * @param { ReactGA } object 
 * 
*/
ReactGA.initialize('G-8Z85QDCDG5');


const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  <React.StrictMode>
     <App />
  </React.StrictMode>
);